.navbar {
  width: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  height: 100px;
  z-index: 1;
  border-bottom: 1px solid white;
}

.navbar__logo {
  width: 300px;
}

.navbar__logo__container {
  display: flex;
}

.navbar__options {
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  height: 100%;
}

.navbar-drawer__button {
  position: absolute;
  right: 20px;
  top: 18px;
  height: 4rem;
  width: 4rem;
  font-size: 3rem;
  color: white;
  background-color: black;
  border: grey 2px solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-drawer__options {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: black;
  border: 1px solid grey;
  border-radius: 5px;
  width: 60%;
  height: fit-content;
  position: absolute;
  right: 20px;
  top: 82px;
  list-style: none;
}

.navbar-drawer__options li {
  border: 1px solid grey;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 810px) {
  .navbar-drawer {
    display: none;
  }
}

@media screen and (max-width: 810px) {
  .navbar__options li {
    display: none;
  }

  .navbar__options {
    justify-content: flex-start;
  }

  .navbar__logo__container {
    max-height: 100%;
  }

  .navbar__logo {
    width: 60%;
    object-fit: cover;
  }

  .navbar {
    display: flex;
  }
}
