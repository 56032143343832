.swiper {
  width: 60%;
  height: 90vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.gallery__title {
  text-align: center;
  color: white;
  padding-top: 40px;
}

@media screen and (max-width: 1250px) {
  .swiper {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .swiper {
    width: 100%;
  }
}
