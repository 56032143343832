.reviewsSwiper {
  width: 80%;
  height: fit-content;
  margin: 40px auto;
}

.reviews__title {
  text-align: center;
}

.reviews__container {
  width: 60%;
  padding-top: 40px;
  margin: 40px auto;
}

@media screen and (max-width: 1300px) {
  .reviewsSwiper {
    width: 100%;
  }

  .reviews__container {
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .reviewsSwiper {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .reviews__container {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .swiper__review {
    width: 100%;
  }
}
