.img-gallery {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, auto);
  width: 60%;
  justify-content: center;
  gap: 10px;
  position: relative;
}

.img-gallery__image {
  overflow: hidden;
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.image-gallery__title {
  margin-top: 120px;
}

.img-gallery__main-img {
  margin: 0 auto;
  width: 20%;
  display: block;
  margin-bottom: 40px;
  border: 2px grey solid;
}

.gallery__back-button {
  font-size: 1.5rem;
  margin: 40px auto 40px auto;
  min-width: 272px;
  max-width: 272px;
  height: 60px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: rgb(231, 111, 1);
  display: block;
}

.gallery-divider {
  width: 100vw;
  background-color: rgb(231, 111, 1);
  height: 7px;
}

.modal__overlay {
  width: 100vw;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.199);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.modal__image {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 80vh;
  max-width: 100%;
  z-index: 2;
}

.expand-btn {
  font-size: 1.5rem;
  width: 60px;
  height: 60px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 35px;
  border: none;
  background-color: rgb(231, 111, 1);
  display: block;
  margin: 10px auto;
}

@media screen and (max-width: 1300px) {
  .img-gallery {
    grid-template-columns: repeat(5, auto);
  }
}

@media screen and (max-width: 1100px) {
  .img-gallery {
    grid-template-columns: repeat(4, auto);
  }
}

@media screen and (max-width: 880px) {
  .img-gallery {
    grid-template-columns: repeat(3, auto);
  }
}

@media screen and (max-width: 660px) {
  .img-gallery {
    grid-template-columns: repeat(2, auto);
  }
}
