@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Poppins", sans-serif;
  text-wrap: balance;
}

a {
  text-decoration: none;
}

a li {
  color: white;
}

a li:hover {
  color: rgb(231, 111, 1);
}

*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(231, 111, 1);
}
