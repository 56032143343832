.send-message__form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.send-message__container {
  background-color: white;
  width: 60%;
  padding-top: 40px;
  padding-bottom: 40px;
  margin: 0px auto;
}

.send-message__message {
  resize: none;
  height: 10rem;
}

.send-message__container input {
  padding: 10px;
  margin-bottom: 20px;
}

.send-message__title {
  text-align: center;
  margin-top: 80px;
  margin-bottom: -40px;
}

.send-message__button {
  font-size: 1.5rem;
  margin: 40px auto 0 auto;
  min-width: 272px;
  max-width: 272px;
  height: 60px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: rgb(231, 111, 1);
}

.message-sent {
  margin-bottom: 3rem;
}

.send-message__button-group {
  display: flex;
  flex-direction: column;
  width: 272px;
  margin: 0 auto;
}

@media screen and (max-width: 1250px) {
  .send-message__container {
    width: 80%;
  }
}

@media screen and (max-width: 850px) {
  .send-message__container {
    width: 100%;
  }

  .send-message__form {
    width: 90%;
  }

  .send-message__button-group {
    width: 150px;
  }

  .send-message__button {
    font-size: 1.2rem;
    min-width: 150px;
    height: 50px;
  }
}
