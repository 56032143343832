.description__container {
  width: 60%;
  margin: 40px auto;
  padding: 40px;
}

.description__title {
  text-align: center;
}

.description__text {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.description__images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.description__images img {
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  border: 1px lightgrey solid;
  transition: 0.4s;
}

.description__images img:hover {
  transform: scale(1.2);
  transition: 0.4s;
}

@media screen and (max-width: 1500px) {
  .description__container {
    width: 80%;
  }
}

@media screen and (max-width: 1250px) {
  .description__text {
    grid-template-columns: none;
    grid-template-rows: auto;
  }

  .description__images {
    justify-items: center;
  }
}

@media screen and (max-width: 800px) {
  .description__container {
    width: 100%;
    padding: 20px;
  }
}
