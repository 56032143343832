.main {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.main__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main__content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
}

.main__content h1 {
  font-size: 3rem;
}

.main__overlay {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 95vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.main__social-icons img {
  margin: 10px 20px;
  width: 50px;
}

.main__social-icons {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main__contact-us__button {
  font-size: 1.5rem;
  min-width: 272px;
  height: 60px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: rgb(231, 111, 1);
}

.main__contact-us__button:hover {
  border-radius: 5px;
  padding-right: 24px;
  padding-left: 8px;
}

.main__contact-us__button:hover::after {
  opacity: 1;
  right: 10px;
}

.main__buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main__contact-us__button:after {
  content: "\00BB";
  position: absolute;
  opacity: 0;
  font-size: 20px;
  line-height: 40px;
  top: 10px;
  right: -20px;
  transition: 0.4s;
}

.zadzwon {
  color: white;
}

.zadzwon:hover {
  color: white !important;
}

span {
  color: rgb(231, 111, 1);
}

@media screen and (max-width: 500px) {
  .main__contact-us__button {
    font-size: 1.2rem;
    min-width: 150px;
    height: 50px;
  }

  .main__content h1 {
    font-size: 2.3rem;
  }

  .main__content h3 {
    font-size: 1rem;
  }

  .main__social-icons img {
    margin: 10px 10px;
    width: 40px;
  }
}
