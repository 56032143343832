.about-us {
  width: 80%;
  margin: 40px auto;
}

.about-us__logo {
  width: 60%;
  margin: 0 auto;
  display: flex;
}

.about-us__img__container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 40px;
  width: 60%;
  margin: 0 auto;
}

.about-us__img {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-us__img:hover {
  transform: translateY(-5px);
}

.about-us__text {
  width: 60%;
  margin: 40px auto;
}

.about-us__title {
  margin-top: 120px;
}

@media screen and (max-width: 1250px) {
  .about-us__logo,
  .about-us__text,
  .about-us__img__container {
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  .about-us__logo,
  .about-us__text,
  .about-us__img__container {
    width: 100%;
  }

  .about-us {
    width: 90%;
  }
}

@media screen and (max-width: 750px) {
  .about-us__img__container {
    grid-template-columns: auto;
  }
}
