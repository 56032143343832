.offer-block__content {
  display: grid;
  width: 60%;
  margin: 0 auto;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-items: center;
}

.offer-block {
  flex: 1;
  width: 90%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: #333;
  transition: transform 0.3s ease;
}

.offer-block:hover {
  transform: translateY(-5px);
}

.offer-block__image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.offer-block__text {
  padding: 20px;
  text-align: center;
}

.offer-block__text h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #222;
}

.offer-block__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.offer-block:hover .offer-block__overlay {
  opacity: 1;
}

.offer-block__overlay p {
  color: #fff;
  text-align: center;
}

.offer-block__title {
  color: #fff;
  text-align: center;
  /* padding: 20px; */
  margin-bottom: 40px;
  background-color: #333;
}

/* Responsive adjustments */
@media screen and (max-width: 1400px) {
  .offer-block__content {
    width: 80%;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .offer-block__content {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .offer-block__content {
    grid-template-columns: 1fr;
  }
}
