.footer {
  text-align: center;
  height: fit-content;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  padding: 40px;
}

.footer__social-icons img {
  width: 50px;
  margin: 10px 10px;
}
